import React from 'react';
import ItemcardDevelopPlan from "./itemcardDevelopPlan";
import ItemcardOutline from "./itemcardOutline";
import ItemCardLR from "./itemcardLR";
import ItemCardUD from "./itemcardUD";
import ItemCardUDLR from "./itemcardUDLR";
import ItemcardDescription from "./itemcardDescription";
import ItemcardImages from "./itemcardImages";

const LayoutSwitch = function(contents) {
    switch(contents.layout) {
        case 'itemcardDevelopPlan':
            return <ItemcardDevelopPlan data={contents.data} />;
        case 'itemcardOutline':
            return <ItemcardOutline data={contents.data} />;
        case 'itemcardLR':
            return <ItemCardLR data={contents.data} />;
        case 'itemcardUD':
            return <ItemCardUD data={contents.data} />;
        case 'itemcardUDLR':
            return <ItemCardUDLR data={contents.data} />;
        case 'itemcardDescription':
            return <ItemcardDescription data={contents.data} />;
        case 'itemcardImages':
            return <ItemcardImages data={contents.data} />;
        default:
            return <ItemCardUDLR data={contents.data} />;
    }
}

export default LayoutSwitch