import React from 'react';
import './App.css';
import {BrowserRouter, Switch, Route, Router} from 'react-router-dom'
import About from './components/About';
import Landing from './components/Landing';
import WorkPage from './components/WorkPage';
import LabPage from './components/LabPage';
import NotFound from "./components/NotFound"
import Footer from './components/footer';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import WorkDetailPage from "./components/WorkDetailPage";
import Header from './components/header'


var history = createBrowserHistory();
history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
});

ReactGA.initialize('UA-90155456-4');
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const p_status = {
    status: 'work',
    title: '디폴트'
  }
  return (
      <Router history={history}>
        {/* <Header></Header> */}
        <Route exact path={["/","/about","/lab","/work"]} component={Header} />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/about" component={About} />
          <Route path="/lab" component={LabPage} />
          <Route path="/work" component={WorkPage} />
          <Route component={NotFound} />
          {<Footer></Footer>}
        </Switch>
        <Route exact path={["/","/about","/lab","/work"]} component={Footer} />
      </Router>
  );
}

export default App;
