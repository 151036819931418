// page/lp.js
import React from "react";

function ItemCard({ data }) {
  return (
    <li className="component component--item_card" >

      { Array.isArray(data) && data.length > 0 &&
        <div className="">
          <div className="side_2_table">
            {data.map((d, insertIndex) => {
              return (
                <div key={insertIndex} className="side_2_ud">
                  <div className="up">
                    <img style={{width:'100%'}} src={d.image.url} className="image--itemcard" alt="" />
                    <span>{d.image.desc && "<"+d.image.desc+">"}</span>
                  </div>
                  <div className="down">
                    <div className="name">{d.name}</div>
                    {d.desc.map((dc, insertIndex2) => {
                      return ( 
                        <div key={insertIndex2} className="dc">{dc}</div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }
      

      {/* <div className="left" style={{width:'60%'}}>
        <img style={{width:'100%'}} src={data.image.url} className="image--itemcard" alt="" />
        <span> {"<"+data.image.desc+">"}</span>
      </div>
      <div className="right" style={{width:'40%'}}>

      </div> */}
      {/* <div className="work_col_3">
        <div className="team" dangerouslySetInnerHTML={{ __html:itemdata.team}}></div>
        <div className="tech" dangerouslySetInnerHTML={{ __html:itemdata.tech}}></div>
        <div className="outputs">
          {itemdata.outputs && itemdata.outputs.map((o, insertIndex) => {
            return (
              <p>{o}</p>
            );
          })}
        </div>
      </div>
      <div className="results">{itemdata.results}</div> */}
      <br/>
    </li>
  );
}
export default ItemCard;




// "id" : 1,
// "title" : "Samsung DA Syndication Monitoring",
// "client" : "Samsung",
// "period" : "2017.03~2017.08",
// "steps" : ["Develop Plan","Outline","Scraping","Main Function","Results"],
// "goal" : "삼성가전 리테일러 웹 사이트 캡쳐 및 스크래핑",
// "team" : "개발 2명, UX 1명",
// "tech" : "AWS, Ubuntu, Nginx, Cron, Phantomjs, Laravel(Blade), JQuery, Bootstrap, Google Chart",
// "outputs" : ["매달 15일, 리테일러 사이트 캡쳐 및 스크래핑 자동화", "Web기반 모니터링 관리자 페이지", "모니터링 요약 Dashboard 및 Excel, Pdf 보고서 export"],
// "results" : "12차 캡쳐 및 스크래핑 / 사용자 모니터링",
// "main_image" : "/assets/images/portfolio/m_001jpg",



