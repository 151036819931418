// page/lp.js
import React from "react";
import { isMobile } from "react-device-detect";

function ItemCard({ data }) {
  return (
    <li className="component component--item_card">
      { Array.isArray(data) && data.length > 0 &&
        <div className="">
          {data.map((d, insertIndex) => {
            return (
              <div key={insertIndex} className="side_2">
                <div className="left" >
                  <img style={{width:'100%'}} src={d.image.url} className="image--itemcard" alt="" />
                  <span>{d.image.desc && "<"+d.image.desc+">"}</span>
                </div>
                <div className="right" >
                    <div className="name">{d.name}</div>
                    {d.desc.map((dc, insertIndex2) => {
                      return ( 
                        <div key={insertIndex2} className="dc">{dc}</div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      }
      <br/>
    </li>
  );
}
export default ItemCard;

