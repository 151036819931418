import React from 'react';
import Header from './header';
import Contact from './Contact';
import data from '../data/yourdata';
import {Link} from 'react-router-dom'

const About = () =>{
return (
  <div>
    {/* <Header name={data.name} contactEmail={data.contactEmail}></Header> */}
    <div className="about_container">
      <h1>{data.aboutTitle}</h1>
      <p>{data.aboutPara}</p>
      <div className="about_contact">
        <Contact
          contactEmail={data.contactEmail}
          contactPara={data.contactPara}
          socialLinks={data.social}
        ></Contact>
      </div>
      <div className="about_work career">
        <h2>Career</h2>
        <div className="contents">
          <a target='_blank' href={"https://www.neowiz.com/neowiz"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Neowiz - Purple Web Development Team / HTML5 Game Developer </p>
          </a>
          <p className="sub">06.2023 ~ present / Gyeonggi-do, Korea</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://wemade.com/main/En"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Wemade - Deif Contents Team / Web3 Frontend Engineer </p>
          </a>
          <p className="sub">10.2022 ~ 2023.06 / Gyeonggi-do, Korea</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://www.dabeeo.com/?lang=en"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Dabeeo - Service Development Team / Web Graphic Engineer </p>
          </a>
          <p className="sub">02.2021 ~ 10.2022 / Seoul, Korea</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://www.kidspay.co.kr/"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Kidspay - COO / UX Engineer </p>
          </a>
          <p className="sub">01.2020 ~ 07.2020 / Gyeonggi-do, Korea</p>
        </div>
        <div className="contents">
          <p className="main"> - ArtKitTec - CEO / Full stack Developer</p>
          <p className="sub">11.2017 ~ 12.2019 / Seoul, Korea</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://www.groupidd.com/"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Group IDD - Creative Technology Lab / Senior Prototype Technician </p>
          </a>
          <p className="sub">09.2016 ~ 08.2017 / Seoul, Korea</p>
        </div>
        <div className="contents">
          <p className="main"> - Dmajor - Development Team / Junior Frontend Developer </p>
          <p className="sub">07.2015 ~ 09.2016 / Gyeonggi-do, Korea</p>
        </div>
        <div className="contents">
          <p className="main"> - Addpac Technology - Smart Management Team / Junior UI Developer</p>
          <p className="sub">01.2014 ~ 06.2015 / Seoul, Korea</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://www.nhn.com/ko/index.nhn"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> NHN - Naver Japan. UX Team / UI/UX Designer </p>
          </a>
          <p className="sub">11.2012 ~ 03.2013 / Gyeonggi-do, Korea</p>
        </div>
      </div>
      
      <div className="about_work career">
        <h2>Education</h2>
        <div className="contents">
          <a target='_blank' href={"https://en-design.kookmin.ac.kr/major/industrial/curriculum"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Kookmin University / Bachelor of Arts in Industrial Design </p>
          </a>
          <p className="sub">03.2005  ~ 02.2013 / Seoul, Korea</p>
        </div>
      </div>

      <div className="about_work project">
        <h2>Projects</h2>


        <div className="contents">
          <a target='_blank' href={"https://pnix.exchange/"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> PNIX DEX / Wemade </p>
          </a>
          <p className="sub">10.2022 ~ 07.2023</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://ref-maps.dabeeomaps.com/"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Dabeeo maps API & Editor / Dabeeo </p>
          </a>
          <p className="sub">02.2021 ~ 10.2022</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://webxr-demo.dabeeomaps.com/"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Indoor Navigation (WebXR) / Dabeeo </p>
          </a>
          <p className="sub">12.2021 ~ 02.2022</p>
        </div>
        {/* <div className="contents">
          <a target='_blank' href={"https://expo.dabeeo.com/smartexpo21/index.html?lang=en&start=1"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Smart Expo Navigation / Dabeeo</p>
          </a>
          <p className="sub">06.2021 ~ 07.2021 ~</p>
        </div> */}
        <div className="contents">
          <Link to={"/work/18"} ><p className="main"> <img src="triangle_bl.png" /> Kidspay Platform / Kidspay</p></Link>
          <p className="sub">01.2020 ~ 07.2020</p>
        </div>
        <div className="contents">
          <Link to={"/work/1"} ><p className="main"> <img src="triangle_bl.png" /> ArtKitTec Platform / ArtKitTec</p></Link>
          <p className="sub">04.2018 ~ 12.2019</p>
        </div>
        <div className="contents">
          <Link to={"/work/2"} ><p className="main"> <img src="triangle_bl.png" /> Samsung DA Syndication Monitoring / Samsung</p></Link>
          <p className="sub">03.2017 ~ 06.2017</p>
        </div>
        <div className="contents">
          <Link to={"/work/4"} ><p className="main"> <img src="triangle_bl.png" /> 3M Post-it BTS, BTO / 3M</p></Link>
          <p className="sub">02.2017 ~ 06.2017</p>
        </div>
        <div className="contents">
          <Link to={"/work/15"} ><p className="main"> <img src="triangle_bl.png" /> Facebook live reaction  / Group IDD</p></Link>
          <p className="sub">01.2017 ~ 02.2017</p>
        </div>
        <div className="contents">
          <Link to={"/work/9"} ><p className="main"> <img src="triangle_bl.png" /> Air Berlin Hackathon / Air Berlin</p></Link>
          <p className="sub">10.2016 ~ 11.2016</p>
        </div>
        <div className="contents">
          <Link to={"/work/5"} ><p className="main"> <img src="triangle_bl.png" /> Group IDD UX team Site / Group IDD</p></Link>
          <p className="sub">09.2016 ~ 11.2016</p>
        </div>
        <div className="contents">
          <Link to={"/work/6"} ><p className="main"> <img src="triangle_bl.png" /> Asics Fuzex X COOL RUNNERS / Asics</p></Link>
          <p className="sub">01.2016 ~ 07.2016</p>
        </div>
        <div className="contents">
          <Link to={"/work/11"} ><p className="main"> <img src="triangle_bl.png" /> Saladgram Site / Saladgram</p></Link>
          <p className="sub">04.2016 ~ 06.2016</p>
        </div>
        <div className="contents">
          <p className="main"> - Samsung TV / Samsung</p>
          <p className="sub">02.2016 ~ 02.2016</p>
        </div>
        <div className="contents">
          <Link to={"/work/10"} ><p className="main"> <img src="triangle_bl.png" /> Changim Application / Changim</p></Link>
          <p className="sub">10.2015 ~ 02.2016</p>
        </div>
        <div className="contents">
          <Link to={"/work/14"} ><p className="main"> <img src="triangle_bl.png" /> Ocean Park – Hong Kong / OceanPark</p></Link>
          <p className="sub">12.2015 ~ 01.2016</p>
        </div>
        <div className="contents">
          <Link to={"/work/13"} ><p className="main"> <img src="triangle_bl.png" /> Maxim TOP Passion Co. Ltd. / DongSuh Food</p></Link>
          <p className="sub">11.2015 ~ 12.2015</p>
        </div>
        <div className="contents">
          <Link to={"/work/8"} ><p className="main"> <img src="triangle_bl.png" /> WeChangeMakers / Dmajor </p></Link>
          <p className="sub">08.2015 ~ 02.2016</p>
        </div>
        <div className="contents">
          <Link to={"/work/7"} ><p className="main"> <img src="triangle_bl.png" /> Samsung Blog / Samsung</p></Link>
          <p className="sub">07.2015 ~ 08.2016</p>
        </div>
        <div className="contents">
          <Link to={"/work/3"} ><p className="main"> <img src="triangle_bl.png" /> Smart Home / Addpac Technology</p></Link>
          <p className="sub">08.2014 ~ 06.2015</p>
        </div>
        <div className="contents">
          <Link to={"/work/12"} ><p className="main"> <img src="triangle_bl.png" /> Smart Meeting Scheduler / Addpac Technology </p></Link>
          <p className="sub">01.2014 ~ 04.2014</p>
        </div>
        <div className="contents">
          <Link to={"/work/16"} ><p className="main"> <img src="triangle_bl.png" /> Cafe Rasilla / Rasilla</p></Link>
          <p className="sub">10.2013 ~ 01.2014</p>
        </div>
        <div className="contents">
          <Link to={"/work/17"} ><p className="main"> <img src="triangle_bl.png" /> Graduation Exhibition Site / Kookmin University</p></Link>
          <p className="sub">2009, 2010, 2011</p>
        </div>
      </div>



      <div className="about_work experience">
        <h2>Experience</h2>


        <div className="contents">
          <a target='_blank' href={"https://www.udemy.com/course/react-three-fiberr-3f/?referralCode=6A961743401CE8C84DD6"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> React Three Fiber (R3F) - English / Udemy </p>
          </a>
          <p className="sub"> Lectures / 02.2024 ~ present </p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://inf.run/vzWuJ"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> React Three Fiber (R3F) - Korean / Inflearn  </p>
          </a>
          <p className="sub"> Lectures / 01.2024 ~ present</p>
        </div>
        <div className="contents">
          <p className="main"> - Lab Coming Day / ARTS KOREA LAB </p>
          <p className="sub"> Exhibition / 11.2023 </p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://www.udemy.com/course/react-three-fiber-r3f/?referralCode=6F4432329173214FF3FB"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> React Three Fiber (R3F) - Korean / Udemy  </p>
          </a>
          <p className="sub"> Lectures / 11.2023 ~ present</p>
        </div>
        <div className="contents">
          <p className="main"> - Generative art for NFTs / MODULABS</p>
          <p className="sub">Activities / 02.2023 ~ 12.2023</p>
        </div>
        <div className="contents">
          {/* <a target='_blank' href={"https://baeowp.io/"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> NFT Art project / BAEOWP </p>
          </a> */}
          <Link to={"/lab/24"} ><p className="main"> <img src="triangle_bl.png" /> NFT Art project / BAEOWP </p></Link>
          <p className="sub">Activities / 10.2021 ~ 06.2022</p>
        </div>
        <div className="contents">
          <Link to={"/lab/1"} ><p className="main"> <img src="triangle_bl.png" /> NeurIPS Workshop 2019 / NeurIPS 2019 Vancouver Canada</p></Link>
          <p className="sub">Exhibition / 12.2019</p>
        </div>
        <div className="contents">
          <Link to={"/lab/6"} ><p className="main"> <img src="triangle_bl.png" /> Creative Code / Art Center Nabi</p></Link>
          <p className="sub">Exhibition / 12.2019</p>
        </div>
        <div className="contents">
          <Link to={"/lab/5"} ><p className="main"> <img src="triangle_bl.png" /> Can A.I be Creative? / Punto blu</p></Link>
          <p className="sub">Exhibition / 11.2019</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://drive.google.com/file/d/1oAHhxjuT2zXiGDPV_BGpsw1tW3VI4MGa/view?usp=sharing"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> NeurIPS - Artwork Session / NeurIPS Creativity </p>
          </a>
          <p className="sub">Prize / 10.2019</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://drive.google.com/file/d/1yKsb2QdDwwvnj_GSlAqSTENpSavL8-qV/view?usp=sharing"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> NeurIPS - Paper Session / NeurIPS Creativity</p>
          </a>
          <p className="sub">Prize / 10.2019</p>
        </div>
        <div className="contents">
          <p className="main"> - Deep Learning College / MODULABS</p>
          <p className="sub">Activities / 04.2019 ~ 12.2019</p>
        </div>
        <div className="contents">
          <p className="main"> - SBC Start-up Academy / KOSME</p>
          <p className="sub">Activities / 03.2018 ~ 01.2019</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://fb.watch/24OcRmdw8p/"} >
            <p className="main"><img className="plus" src="plus_bl.png" /> AOW (Interactive art) / Seoul Art Space</p>
          </a>
          <p className="sub">Exhibition / 10.2017</p>
        </div>
        <div className="contents">
          <p className="main"> - Maker Support business / KOFAC</p>
          <p className="sub">Activities / 05.2017 ~ 12.2017</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://spv.taejaehan.com/"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Sphere Visualizer / TaeJae Han</p>
          </a>
          <p className="sub">Personal work / 05.2017</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://www.facebook.com/InteractiveArtLab"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Interactive art of web - Leader / MODULABS</p>
          </a>
          <p className="sub">Activities / 02.2017 ~ 02.2018</p>
        </div>
        <div className="contents">
          <a target='_blank' href={"https://spring_waltz.taejaehan.com/"} >
            <p className="main"> <img className="plus" src="plus_bl.png" /> Spring Waltz / TaeJae Han</p>
          </a>
          <p className="sub">Personal work / 02.2017 ~ 04.2017</p>
        </div>

        <div className="contents">
          <Link to={"/work/9"} ><p className="main"> <img src="triangle_bl.png" /> Best Business Experience 3rd / Air Berlin</p></Link>
          <p className="sub">Prize / 01.12.2016</p>
        </div>
        <div className="contents">
          <p className="main"> - Founding. 4th / Seoul Univ</p>
          <p className="sub">Prize / 03.04.2012</p>
        </div>
        <div className="contents">
          <Link to={"/lab/18"} ><p className="main"> <img src="triangle_bl.png" /> Entertainment. Finalist Prize  - Asia Digital Art Award</p></Link>
          <p className="sub">Prize / 18.03.2012</p>
        </div>

      </div>

    </div>
  </div>
);
}
export default About;