// page/lp.js
import React from "react";
import ReactPlayer from "react-player"
import { isMobile } from "react-device-detect";

function ItemCard({ data }) {
  return (
    <li className="component component--item_card">
      <h2>{data.goal}</h2>

      <div>
        {data.video && 
          data.video.map((v, insertIndex) => {
              return (
                <div key={insertIndex} style={{margin:'20px 0'}}>
                  {v.type == "youtube" ?
                    <ReactPlayer
                      width="100%"
                      height={isMobile ? "200px" : "473px"}
                      url={v.url}
                      loop={true}
                      controls={true}
                    />
                  : 
                    v.type == "local_gif" ?
                    <img style={{width:'100%'}} src={v.url} className="image--itemcard" alt="" />
                    :
                    <ReactPlayer
                      url={v.url}
                      config={{
                        facebook: {
                          appId: '199518258535611'
                        }
                      }}
                    />
                  }
                </div>
              )
          })
        }
      </div>
      <img style={{width:'100%'}} src={data.image.url} className="image--itemcard" alt="" />
      <p>{data.image.desc && "<"+data.image.desc+">"}</p>

      
      <div className="work_col_3">
        <div className="team" dangerouslySetInnerHTML={{ __html:data.team}}></div>
        <div className="tech" dangerouslySetInnerHTML={{ __html:data.tech}}></div>
        <div className="outputs">
          
          {/* <img style={{width:'100%'}} src="/right-triangle.png" /> */}
          {data.outputs && data.outputs.map((o, insertIndex) => {
            return (
              <p  key={insertIndex} dangerouslySetInnerHTML={{ __html:o}}></p>
            );
          })}
        </div>
      </div>
      <div className="results" dangerouslySetInnerHTML={{ __html:data.results}}></div>

      {data.related_link && data.related_link.map((link, li) => {
        return (
        <p key={li}  className="detail_main_link">
          <a target='_blank' href={link.url} >
            [ {link.name} ]
          </a>
        </p>
       )
      })}

      <br/>
    </li>
  );
}
export default ItemCard;

