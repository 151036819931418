import PropTypes from 'prop-types';
import React from 'react';
import { Element } from 'react-scroll';

const ElementsWrapper = ({ children, items, style, className }) => (
    <>
        {children.map((child, i) => (
            // Element 자차에서 name을 빼서 타겟을 그 아래 child에서 넣을수있도록 한다 by tjhan 20201130
            // <Element style={style} className={className} name={items[i].target} key={i} >{child}</Element>
            <Element style={style} className={className} key={i} >{child}</Element>
        ))}
    </>
);

ElementsWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    items: PropTypes.array.isRequired,
    style: PropTypes.object,
    className: PropTypes.string
}

export default ElementsWrapper;