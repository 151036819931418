import React, { Component } from "react";

var style = {
    // backgroundColor: "white",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    // padding: "20px",
    // position: "fixed",
    // left: "0",
    // bottom: "0",
    // height: "500px",
    // width: "100%",
    height: '90%',
}

var phantom = {
  display: 'block',
//   padding: '20px',
//   height: '60px',
    height: '10%',
//   width: '100%',
}

function Footer({ }) {
    const footerHeight = window.innerHeight - 200;
    // console.log(window.innerHeight);
    // console.log(footerHeight);
    return (
        <div className="footer">
            {/* <div style={phantom} /> */}
            <div style={style}>
                <p style={{fontSize:"2.2em", margin:'10px'}} >TaeJae Han.</p>
                <p style={{fontSize:"1.3em", margin:'5px'}} >2024.</p>
                {/* <p style={{fontSize:"1.3em"}} >hantaejae@gmail.com</p> */}
                
                <div style={{fontSize:"1.3em"}}>
                    <span style={{marginRight:"10px"}}><a style={{color:"#41811c"}} href={`mailto:hantaejae@gmail.com`}>email</a></span>
                    {/* <span style={{marginRight:"10px"}}><a style={{color:"#41811c"}} target="_blank" href="https://www.facebook.com/TaejaeHan">facebook</a></span> */}
                    <span style={{marginRight:"10px"}}><a style={{color:"#41811c"}} target="_blank" href="https://www.youtube.com/@worldofcodings">youtube</a></span>
                    <span><a style={{color:"#41811c"}} target="_blank" href="https://www.linkedin.com/in/taejae-han-8b5b32ba/">linkedin</a></span>
                </div>
            </div>
        </div>
    )
}

export default Footer