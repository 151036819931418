import React from 'react';
import {Link} from 'react-router-dom'
import { SampleConsumer } from '../AppContext';
import { useHistory } from "react-router-dom";

const Header = ({name, contactEmail})=>{
    const history = useHistory();
    // const linkTo = function(to){
    //     if(window.location.pathname != to ){
    //         history.push(to);
    //         window.scrollTo(0, 0)
    //     }
    // }
    const isActive = function(path){
        if(window.location.pathname == path ){
            return true;
        }else{
            return false;
        }
    }
    return(
        <div>
            <div className="header_container">
                <Link to="/"><h1>TaeJaeHan.</h1></Link>
                <nav className="title_wrap">
                    <ul>
                        <li className={isActive("/about")?'isActive':''}><Link to="/about" >about</Link></li>
                        <li className={isActive("/work")?'isActive':''}><Link to="/work" >work</Link></li>
                        <li className={isActive("/lab")?'isActive':''}><Link to="/lab" >Lab</Link></li>
                        {/* <li><a href={`mailto:${contactEmail}`}>contact</a></li> */}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Header