import React from "react";


function ItemCard({ data }) {
  return (
    <li className="component component--item_card">
      { Array.isArray(data) && data.length > 0 &&
        <div className="ud_layout">
          {data.map((d, insertIndex) => {
            return (
              <div key={insertIndex} >
                <img style={{maxWidth:'100%'}} src={d.image.url} className="image--itemcard" alt="" />
                <div>{d.image.desc && "<"+d.image.desc+">"}</div>
                <h4>{d.name}</h4>
                {d.desc.map((dc, insertIndex2) => {
                  return ( 
                    <div key={insertIndex2} className="dc">{dc}</div>
                  );
                })}
                {d.related_link && d.related_link.map((link, li) => {
                  return (
                  <p key={li}  className="detail_main_link">
                    <a target='_blank' href={link.url} >
                      [ {link.name} ]
                    </a>
                  </p>
                )
                })}
              </div>
            );
          })}
          
        </div>
        
        }
    </li>
  );
}
export default ItemCard;




