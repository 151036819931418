import React, { useLayoutEffect, useState } from 'react';
import Header from './header'
import WorkListPage from './WorkListPage';
import Contact from './Contact';
// import WorkDetailPage from "./WorkDetailPage";
import WorkDetailPage from "./WorkDetailPage";
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import data from '../data/yourdata';
import Footer from './footer';
import NotFound from "./NotFound"

// import NavBar, { ElementsWrapper } from "react-scrolling-nav";
import StackGrid from "react-stack-grid";
import { SampleConsumer } from '../AppContext';
const WorkPage = ({ match })=>{
    // const [windowWidth, windowHeight] = useWindowSize();
    return(
      <div className="App">
        <Route exact path={match.path} component={WorkListPage} />
        <Route path={`${match.path}/:id`} component={WorkDetailPage} />
      </div>
    )
}

export default WorkPage