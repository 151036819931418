import React, { Component } from "react";
import layout_switch from "./itemcardLayout";
import labs from "../data/labs.json"
import '../App.css';
import NavBar, { ElementsWrapper } from './navbar';
import HeaderDetail from './header_detail'
import { isMobile } from "react-device-detect";
import ProgressBar from '@ramonak/react-progress-bar';

class WorkDeail extends Component {
  id = 1;
  state = {};
  match
  constructor(props) {
    super(props);
    this.state = {        
      ReturnMessage:"",
      imageIsReady: false,
      imagePercentage : 0,
      imageLoadedNum : 0,
      imageLength : 0,
    };

  }

  returnNotFound(){
    this.props.history.replace("/");
  }
  
  imgLoad (data, setP, readyIm) {
    return new Promise(function(resolve, reject) {
        var img = new Image();
        // img.src = data.imageSrc;
        img.src = data;
        img.onload = function() {
            resolve(img);
            setP(1);
        }
        img.onerror = reject
    });
  }
  readyImage = () => {
      this.setState({ imageIsReady: true });
  }
  setImagePercentage = (plus) => {
    var p = 100 / this.state.imageLength;
    this.setState({ imageLoadedNum: this.state.imageLoadedNum + plus });
    this.setState({ imagePercentage: parseInt(this.state.imageLoadedNum * p) });
    if(this.state.imageLoadedNum >= this.state.imageLength){
      this.readyImage();
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    const setImagePercentage = this.setImagePercentage;
    const readyImage = this.readyImage;


    const lab = labs.find((lab) => lab.id === Number(this.props.match.params.id))
    this.setState({ imageLength: lab.loadurl.length });
    let imageLoadpromises = lab.loadurl.map((laburl,index) => this.imgLoad(laburl, setImagePercentage, readyImage));
    // Promise.all(imageLoadpromises ).then(function(images){
    //     // console.log('images All READY')
    //     readyImage();
    // }).catch(function(err){ 
    //     console.log('One or more images did not load')
    // });
  }
  render() {
    const lab = labs.find((lab) => lab.id === Number(this.props.match.params.id))
    if(typeof lab == "undefined"){
      this.returnNotFound();
      return (  
        <></>
      )
    }else{

      const projectSteps = lab.steps

      const coverWidth = window.innerWidth <= 1000 ? window.innerWidth*0.8 : 1000;
      const navWidthInit = coverWidth/projectSteps.length;
      const navWidth = navWidthInit > 86 ? 86 : navWidthInit < 58 ? 58 : navWidthInit;

      return (     
        
        <>
          <HeaderDetail head={{'title':lab.title, 'period':lab.period}} ></HeaderDetail>
            <NavBar 
              navbarStyle={{wordBreak: "break-all", top:100 }} 
              navWidth={navWidth}
              coverWidth={coverWidth}
              height={isMobile ? 50 : 60}
              items={projectSteps} 
              offset={-200} 
              duration={500} 
              delay={0} 
              className="" 
              // containerId="element_body"
            />
            {
            this.state.imageIsReady ? 
              <div className="work_container" >
                <ElementsWrapper items={projectSteps}>
                  {projectSteps &&
                    projectSteps.map((step_nav, i) => {
                      return (
                        <div key={i} className="work_steps" name={step_nav.target} >
                          <div className="step_name">{ step_nav.label }</div>
                          <br/>
                          {layout_switch(lab.contents[step_nav.target])}
                        </div>
                      );
                  })}
                  
                </ElementsWrapper>
              </div>
            :
             <ProgressBar bgcolor="#41811c" margin="170px 0 0 0" labelSize="0" height="2px" borderRadius="2px" completed={this.state.imagePercentage}/>
            
            }
          
          
        </>
      );
    }
  }
}

export default WorkDeail;

