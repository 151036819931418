import React from 'react';
import {Link} from 'react-router-dom'
import { SampleConsumer } from '../AppContext';
import { useHistory } from "react-router-dom";

const Header = ({head})=>{
    const history = useHistory();
    
    const goBack = (e) => {
        // console.log("goBack");
        // console.log(history.location.pathname);
        history.goBack();
        e.preventDefault();
    };
    return(
        <div>
            <div className="header_container">
                <Link to="/"><h1>TaeJaeHan.</h1></Link>
                <div className="title_wrap">
                    <div className="title">
                    { head.title }
                    </div>
                    <div className="period">
                    { head.period }
                    </div>
                </div>
                <div className="close_wrap" >
                    <img style={{width:"50px"}} onClick={goBack} src="/assets/close.png" />
                </div>
            </div>
        </div>
    )
}

export default Header