// page/lp.js
import React from "react";
import { isMobile } from "react-device-detect";
function ItemCard({ data }) {
  return (
    <li className="component component--item_card" >

      { Array.isArray(data) && data.length > 0 &&
        <div className="">
          <div className="side_2_table">
            {data.map((d, insertIndex) => {
              return (
                <div key={insertIndex}  className="side_2_ud">
                  <div className="up">
                    {d.main_image && 
                      <>
                      <img style={{width:"100%"}} src={d.main_image.url} className="image--itemcard" alt="" />
                      <p>{d.main_image.desc && "<"+d.main_image.desc+">"}</p>
                      </>
                    }
                    {d.image.map((img, insertIndex) => {
                      return ( 
                        <img key={insertIndex} style={isMobile ? {width:d.m_width}:{width:d.width} } src={img} className="image--itemcard" alt="" />
                      );
                    })}
                    <span>{d.image.desc && "<"+d.image.desc+">"}</span>
                  </div>
                  <div className="down">
                    {d.desc}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }
      <br/>
    </li>
  );
}
export default ItemCard;
