import React from 'react';
import LabListPage from './LabListPage';
// import LabDetailPage from "./LabDetailPage";
import LabDetailPage from "./LabDetailPage";
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import Footer from './footer';

const LapPage = ({ match })=>{
    return(
      <div className="App">
        <Switch>
          <Route exact path={match.path} component={LabListPage} />
          <Route path={`${match.path}/:id`} component={LabDetailPage} />
        </Switch>
        {/* <LabListPage></LabListPage> */}
        {/* <Footer></Footer> */}
      </div>

    )
}

export default LapPage