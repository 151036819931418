// page/lp.js
import React from "react";
import { isMobile } from "react-device-detect";

function ItemCard({ data }) {
  return (
    <li className="component component--item_card" >
      { Array.isArray(data) && data.length > 0 &&
        <div className="">
          <div className="side_2_table">
            {data.map((d, insertIndex) => {
              return (
                <div key={insertIndex} className="side_2_ud">
                  <img style={isMobile ? {width:"100%"}:{width:'70%'}} src={d.image.url} className="image--itemcard" alt="" />
                  <div>{d.image.desc && "<"+d.image.desc+">"}</div>

                  <div style={{textAlign:'left'}}>
                  {d.desc && d.desc.map((dc, insertIndex) => {
                    return ( 
                      <div key={insertIndex} className="dc" dangerouslySetInnerHTML={{ __html:dc}}></div>
                    );
                  })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }


    </li>
  );
}
export default ItemCard;




// "id" : 1,
// "title" : "Samsung DA Syndication Monitoring",
// "client" : "Samsung",
// "period" : "2017.03~2017.08",
// "steps" : ["Develop Plan","Outline","Scraping","Main Function","Results"],
// "goal" : "삼성가전 리테일러 웹 사이트 캡쳐 및 스크래핑",
// "team" : "개발 2명, UX 1명",
// "tech" : "AWS, Ubuntu, Nginx, Cron, Phantomjs, Laravel(Blade), JQuery, Bootstrap, Google Chart",
// "outputs" : ["매달 15일, 리테일러 사이트 캡쳐 및 스크래핑 자동화", "Web기반 모니터링 관리자 페이지", "모니터링 요약 Dashboard 및 Excel, Pdf 보고서 export"],
// "results" : "12차 캡쳐 및 스크래핑 / 사용자 모니터링",
// "main_image" : "/assets/images/portfolio/m_001jpg",



