// page/lp.js
import React from "react";

function ItemCard({ data}) {
  return (
    <li className="component component--item_card">
        { Array.isArray(data) && data.length > 0 &&
          <div className="">
            <div className="side_2_table">
              {data.map((d, insertIndex) => {
                return (
                  <div key={insertIndex} style={{textAlign:'left', fontSize:"1.2em"}}>
                    {d.desc && d.desc.map((dc, insertIndex2) => {
                      return ( 
                        <div key={insertIndex2} className="dc">{dc}<br/></div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        }
    </li>
  );
}
export default ItemCard;

