import React from 'react';
import Header from './header'
import data from '../data/yourdata';
import Home from './home';
const Landing = ()=>{
 
    return(
      <div className="App">
        {/* <Header name={data.name} contactEmail = {data.contactEmail}></Header> */}
        <Home name={data.landingPageName} paragraph = {data.landingPagePara} authorImage={data.landingPageImage}></Home>
      </div>
    )
}

export default Landing