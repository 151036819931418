import React, { Component } from "react";
import layout_switch from "./itemcardLayout";
import projects from "../data/projects.json"
import origin_steps from "../data/projects_steps_nav.json"
import NavBar, { ElementsWrapper } from './navbar';
import HeaderDetail from './header_detail'
import { isMobile } from "react-device-detect";
import ProgressBar from '@ramonak/react-progress-bar';

class WorkDeail extends Component {
  id = 1;
  state = {};
  match
  constructor(props) {
    super(props);
    this.state = {        
      ReturnMessage:"",
      imageIsReady: false,
      imagePercentage : 0,
      imageLoadedNum : 0,
      imageLength : 0,
    };
  }

  imgLoad (data, setP, readyIm) {
    return new Promise(function(resolve, reject) {
        var img = new Image();
        // img.src = data.imageSrc;
        img.src = data;
        img.onload = function() {
            resolve(img);
            setP(1);
        }
        img.onerror = reject
    });
  }
  readyImage = () => {
      this.setState({ imageIsReady: true });
  }
  setImagePercentage = (plus) => {
    var p = 100 / this.state.imageLength;
    this.setState({ imageLoadedNum: this.state.imageLoadedNum + plus });
    this.setState({ imagePercentage: parseInt(this.state.imageLoadedNum * p) });
    if(this.state.imageLoadedNum >= this.state.imageLength){
      this.readyImage();
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    const setImagePercentage = this.setImagePercentage;
    const readyImage = this.readyImage;


    const lab = projects.find((lab) => lab.id === Number(this.props.match.params.id))
    this.setState({ imageLength: lab.loadurl.length });
    let imageLoadpromises = lab.loadurl.map((laburl,index) => this.imgLoad(laburl, setImagePercentage, readyImage));
    // Promise.all(imageLoadpromises ).then(function(images){
    //     // console.log('images All READY')
    //     readyImage();
    // }).catch(function(err){ 
    //     console.log('One or more images did not load')
    // });
  }
  returnNotFound(){
    this.props.history.replace("/");
  }
  render() {
    const { items } = this.props;
    const project = projects.find((project) => project.id === Number(this.props.match.params.id))

    if(typeof project == "undefined"){
      this.returnNotFound();
      return (  
        <></>
      )
    }else{

      //proejct에서 step을 가져와서 target중복된 것을 nav에 넣어주고 by tjhan 20201129
      const projectSteps = project.steps
      //target이 오리진과 똑같은것만 렌더함(바뀐것은 다른 target과 합쳐짐) by tjhan 20201129
      const projectStepsList 
      = projectSteps.filter(step =>
        step.target === origin_steps[step.label]
      )

      const coverWidth = window.innerWidth <= 1000 ? window.innerWidth*0.8 : 1000;
      const navWidthInit = coverWidth/projectSteps.length;
      const navWidth = navWidthInit > 86 ? 86 : navWidthInit < 58 ? 58 : navWidthInit;

      return (     
        <>
          <HeaderDetail head={{'title':project.title, 'period':project.period}} ></HeaderDetail>
          
          <NavBar 
            className="" 
            navbarStyle={{wordBreak: "break-all", top:100 }} 
            navWidth={navWidth} 
            coverWidth={coverWidth}
            height={isMobile ? 50 : 60}
            items={projectSteps} 
            offset={-200} 
            duration={500} 
            delay={0} 
          />
          {
            this.state.imageIsReady ? 
              <div className="work_container" >
                <ElementsWrapper items={projectStepsList}>
                  {projectStepsList &&
                    projectStepsList.map((step_nav) => {
                      return (
                        <div className="work_steps" name={step_nav.target} key={project.id}>
    
                          <div className="step_name">{ step_nav.label }</div>
                          <br/>
                          {layout_switch(project.contents[step_nav.target])}
                        </div>
                      );
                  })}
                  
                </ElementsWrapper>
                
              </div>
            :
              <ProgressBar bgcolor="#41811c" margin="170px 0 0 0" labelSize="0" height="2px" borderRadius="2px" completed={this.state.imagePercentage}/>
          }
        </>
      );
    }
    

    
  }
}

export default WorkDeail;

