import PropTypes from 'prop-types';
import React from 'react';
import { isMobile } from 'react-device-detect';

import Nav from './nav';

let currIndex = null;
let nextIndex = 0;
const DEFAULT_NAV_WIDTH = 86;

const Navs = ({ items, offset, duration, delay, coverWidth, navWidth, linkClass, activeLinkClass, containerId }) => {
    navWidth = navWidth ? navWidth : DEFAULT_NAV_WIDTH;
    coverWidth = coverWidth ? coverWidth : items.length * navWidth;
    const coverStyle = {
        width: coverWidth,
        height: "100%",
        overflow: "hidden",
        WebkitMaskBoxImage: "-webkit-linear-gradient(left, transparent, white 50%, transparent)"
    };
    // console.log('coverWidth : ' + coverWidth)
    // console.log('navWidth : ' + navWidth)
    // console.log('items.length : ' + items.length)
    // nav가 1개 이하일때는 moveNav하지 않는다 by tjhan 20201205
    // nav coverwidth가 nav를 다 포함하면 움직이지 않는다
    // var moveNavAllowed = true;
    // if(items.length <= 1 || coverWidth < (navWidth * items.length)){
    //     moveNavAllowed = false;
    // }

    var moveNavAllowed = false;
    if(items.length > 1 && (coverWidth < (navWidth * items.length))){
        moveNavAllowed = true;
    }
    var left = moveNavAllowed ? coverWidth / 2 - navWidth/2 : ((coverWidth - navWidth * items.length)/ 2);
    // console.log("left : " + left);
    const navsStyle = {
        margin: 0,
        // left: coverWidth / 2 - 43,
        // left: moveNavAllowed ? (coverWidth / 2) - 43 : 0 ,
        // left : moveNavAllowed ? 0 : ((coverWidth - navWidth * items.length)/ 2) ,
        left: left,
        paddingLeft: 0,
        position: "relative",
        height: "100%",
        display: "flex",
        listStyle: "none"
    };
    // console.log("navsStyle");
    // console.log(navsStyle);

    var navsNode = React.createRef();

    const inActive = (item) => {
        // console.log('inActive')
        // console.log(item)
    }
    const moveNavs = (newIndex, navWidth) => {
        // console.log('nextIndex : ' + nextIndex)
        // console.log('newIndex : ' + newIndex)
        currIndex = nextIndex;
        nextIndex = newIndex;
        //처음에 render할때 index가 1이상 차이가 나는 경우 잘못 초기left값이 설정되므로 차이가 1이하일때만 움직이게 설정 by tjhan 20201221
        if (Math.abs(currIndex - nextIndex) <= 1 && moveNavAllowed && navsNode.current) {
            // console.log('navsNode')
            // console.log(navsNode.current.style.left)
            let currLeft = Number.parseInt(navsNode.current.style.left);
            const indexOffset = nextIndex - currIndex;
            navsNode.current.style.left = currLeft - indexOffset * navWidth + "px";
            
            // console.log(indexOffset)
            // console.log(navWidth)
            // console.log(navsNode.current.style.left)
        }
    };
    return (
        <div style={coverStyle}>
            <ul style={navsStyle} ref={navsNode}>
                {items.map((item, i) => (
                    <Nav
                        key={i}
                        index={i}
                        item={item}
                        offset={offset}
                        duration={duration} 
                        delay={delay}
                        moveNavs={moveNavs}
                        inActive={inActive}
                        width={navWidth}
                        linkClass={linkClass} 
                        activeLinkClass={activeLinkClass}
                        containerId={containerId} />
                ))}
            </ul>
        </div>
    );
};

Navs.propTypes = {
    items: PropTypes.array.isRequired,
    offset: PropTypes.number,
    duration: PropTypes.number,
    delay: PropTypes.number,
    coverWidth: PropTypes.number,
    navWidth: PropTypes.number
}

export default Navs;