import React from 'react';
import Footer from './footer';
import Header from './header'
import data from '../data/yourdata';
import {Link} from 'react-router-dom'

const NotFound = ({ match })=>{
    return(
      <div>
        <div className="App" >
          <Header name={data.name} contactEmail = {data.contactEmail}></Header>
          <div div className="work_container">This Page you are looking for doesn't exist</div>
          <Link style={{textAlign:"center", color:"blue"}} to="/"><h1>Main-page</h1></Link>
          <Footer></Footer>
        </div>
      </div>
    )
}

export default NotFound;