export default {
  //(Please Do Not Remove The comma(,) after every variable)

  //Change The Website Template
  name: "TaeJaeHan",

  //Contact Email
  contactEmail: "hantaejae@gmail.com",

  // Landing Page Name
  landingPageName: "TaeJae Han",

  // Landing Page Paragraph
  landingPagePara:
    "Is a developer, an artist and a maker who enjoys creative coding for interactive visualization with a 10 year experience. The little big details are what I care about the most when I work on UI/UX. ",
  
    // Landing Page Image (replace - root/public/assets/photo.jpg)
  landingPageImage: "assets/taejaehan.jpg",

  // Change Projects Here
  works: [
    {
      id: 1, //DO NOT CHANGE THIS (Please)😅
      title: "ArtKitTec Platform", //Project Title - Add Your Project Title Here
      // Image Size Should be (300X300 px)
      imageSrc: "/assets/images/portfolio/thumb/m_001_thumb.png",
      color : [211, 212, 214, 255],
      //Project URL - Add Your Project Url Here
      year : ["2019","2018"],
      url: "#"
    },
    {
      id: 2, //DO NOT CHANGE THIS (Please)😅
      title: "Samsung DA Syndication Monitoring",
      imageSrc: "/assets/images/portfolio/thumb/m_002_thumb.png",
      color : [245, 246, 246, 255],
      year : ["2017"],
      url: "#"
    },
    {
      id: 3, //DO NOT CHANGE THIS (Please)😅
      title: "Smart Home",
      imageSrc: "/assets/images/portfolio/thumb/m_003_thumb.png",
      color : [124, 115, 84, 253],
      year : ["2015","2014"],
      url: "#"
    },
    {
      id: 4, //DO NOT CHANGE THIS (Please)😅
      title: "3M Post-it BTS /BTO Campaign",
      imageSrc: "/assets/images/portfolio/thumb/m_004_thumb.png",
      color : [217, 217, 199, 255],
      year : ["2017"],
      url: "#"
    },
    {
      id: 5, //DO NOT CHANGE THIS (Please)😅
      title: "UX Team site",
      imageSrc: "/assets/images/portfolio/thumb/m_005_thumb.png",
      color : [181, 181, 181, 237],
      year : ["2016"],
      url: "#"
    },
    {
      id: 6, //DO NOT CHANGE THIS (Please)😅
      title: "Asics Fuzex X COOL RUNNERS",
      imageSrc: "/assets/images/portfolio/thumb/m_006_thumb.png",
      color : [182, 180, 188, 255],
      year : ["2016"],
      url: "#"
    },
    {
      id: 7, //DO NOT CHANGE THIS (Please)😅
      title: "Samsung Blog", //Project Title - Add Your Project Title Here
      // Image Size Should be (300X300 px)
      imageSrc: "/assets/images/portfolio/thumb/m_007_thumb.png",
      color : [229, 230, 228, 255],
      //Project URL - Add Your Project Url Here
      year : ["2016","2015"],
      url: "/about"
    },
    {
      id: 8, //DO NOT CHANGE THIS (Please)😅
      title: "WeChangeMakers",
      imageSrc: "/assets/images/portfolio/thumb/m_008_thumb.png",
      color : [202, 222, 194, 250],
      year : ["2015","2016"],
      url: "#"
    },
    {
      id: 9, //DO NOT CHANGE THIS (Please)😅
      title: "Air Berlin - MEET",
      imageSrc: "/assets/images/portfolio/thumb/m_009_thumb.png",
      color : [246, 245, 248, 251],
      year : ["2016"],
      url: "#"
    },
    {
      id: 10, //DO NOT CHANGE THIS (Please)😅
      title: "Changim",
      imageSrc: "/assets/images/portfolio/thumb/m_010_thumb.png",
      color : [135, 172, 49, 255],
      year : ["2016","2015"],
      url: "#"
    },
    {
      id: 11, //DO NOT CHANGE THIS (Please)😅
      title: "Saladgram",
      imageSrc: "/assets/images/portfolio/thumb/m_011_thumb.png",
      color : [207, 208, 204, 248],
      year : ["2016"],
      url: "#"
    },
    {
      id: 12, //DO NOT CHANGE THIS (Please)😅
      title: "Smart Scheduler",
      imageSrc: "/assets/images/portfolio/thumb/m_012_thumb.png",
      color : [241, 243, 247, 253],
      year : ["2014"],
      url: "#"
    },
    {
      id: 13, //DO NOT CHANGE THIS (Please)😅
      title: "Maxim TOP Passion Co. Ltd.",
      imageSrc: "/assets/images/portfolio/thumb/m_013_thumb.png",
      color : [202, 200, 197, 255],
      year : ["2015"],
      url: "#"
    },
    {
      id: 14, //DO NOT CHANGE THIS (Please)😅
      title: "Ocean Park – Hong Kong",
      imageSrc: "/assets/images/portfolio/thumb/m_014_thumb.png",
      color : [146, 167, 183, 253],
      year : ["2016"],
      url: "#"
    },
    {
      id: 15, //DO NOT CHANGE THIS (Please)😅
      title: "Facebook Live Reactions demo",
      imageSrc: "/assets/images/portfolio/thumb/m_015_thumb.png",
      color : [211, 212, 214, 255],
      year : ["2017"],
      url: "#"
    },
    {
      id: 16, //DO NOT CHANGE THIS (Please)😅
      title: "Rasilla",
      imageSrc: "/assets/images/portfolio/thumb/m_016_thumb.png",
      color : [170, 167, 165, 255],
      year : ["2014","2013"],
      url: "#"
    },
    {
      id: 17, //DO NOT CHANGE THIS (Please)😅
      title: "Kookmin Univ. Graduation site",
      imageSrc: "/assets/images/portfolio/thumb/m_017_thumb.png",
      color : [213, 210, 210, 255],
      year : ["2011","2010","2009"],
      url: "#"
    },
    {
      id: 18, //DO NOT CHANGE THIS (Please)😅
      title: "Kidspay",
      imageSrc: "/assets/images/portfolio/thumb/m_019_thumb.png",
      color : [211, 212, 214, 255],
      year : ["2020"],
      url: "#"
    },
    {
      id: 19, //DO NOT CHANGE THIS (Please)😅
      title: "Dabeeo Map API",
      imageSrc: "/assets/images/portfolio/thumb/m_020_thumb.png",
      color : [189, 192, 200, 255],
      year : ["2022"],
      url: "#"
    }
  ],
  // Change Projects Here
  labs: [
    {
      id: 1, //DO NOT CHANGE THIS (Please)
      index : 0,
      weightY : 5,
      weightX : 2,
      // Image Size Should be (300X300 px)
      title: "Korean abstract painting Dansaekhwa",  //Project Title - Add Your Project Title Here
      imageSrc: "/assets/images/labs/thumb/l_001_thumb.png",
      color : [211, 212, 214, 255],
      //Project URL - Add Your Project Url Here
      // url: "https://whenemotionsbecomeform.github.io/#author",
      url : "#",
      period : "2019.06~2019.12",
      year : "2019"
    },
    {
      id: 2, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 2,
      title: "Spring Waltz",
      imageSrc: "/assets/images/labs/thumb/l_002_thumb.gif",
      color : [100, 99, 86, 255],
      url : "#",
      // url: "https://spring_waltz.taejaehan.com/",
      period : "2017.02~2017.04",
      year : "2017"
    },
    {
      id: 3, //DO NOT CHANGE THIS (Please)😅
      index : 1,
      weightY : 2,
      weightX : 2,
      title: "Sphere Visualizer",
      imageSrc: "/assets/images/labs/thumb/l_003_thumb.gif",
      color : [16, 17, 19, 255],
      // url: "https://spv.taejaehan.com/",
      url:"#",
      period : "2017.05",
      year : "2017"
    },
    {
      id: 4, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 2,
      title: "String Portrait",
      imageSrc: "/assets/images/labs/thumb/l_004_thumb.png",
      color : [158, 154, 145, 255],
      // url: "https://youtu.be/aAPtRSzcsRs",
      url:"#",
      period : "2018.01~2018.10",
      year : "2018"
    },
    {
      id: 5, //DO NOT CHANGE THIS (Please)😅
      index : 1,
      weightY : 5,
      weightX : 1,
      title: "Can A.I be Creative?",
      imageSrc: "/assets/images/labs/thumb/l_005_thumb.gif",
      color : [97, 95, 97, 255],
      // url: "https://youtu.be/F9gr0ziBMo0",
      url: "#",
      period : "2019.11~2019.12",
      year : "2019"
    },
    {
      id: 6, //DO NOT CHANGE THIS (Please)😅
      index : 2,
      weightY : 2,
      weightX : 3,
      title: "Creative Code",
      imageSrc: "/assets/images/labs/thumb/l_006_thumb.jpg",
      color : [81, 70, 72, 255],
      // url: "https://youtu.be/Hx0IIAdu3hc",
      url: "#",
      period : "2019.11~2019.12",
      year : "2019"
    },
    {
      id: 7, //DO NOT CHANGE THIS (Please)
      index : 0,
      weightY : 2,
      weightX : 1,
      // Image Size Should be (300X300 px)
      title: "AOW Exhibition",  //Project Title - Add Your Project Title Here
      imageSrc: "/assets/images/labs/thumb/l_007_thumb.jpg",
      color : [100, 80, 61, 255],
      //Project URL - Add Your Project Url Here
      // url: "https://fb.watch/24OcRmdw8p/",
      url:"#",
      period : "2018.02",
      year : "2018"
    },
    {
      id: 8, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 2,
      title: "Imagine the Moment",
      imageSrc: "/assets/images/labs/thumb/l_008_thumb.jpg",
      color : [122, 120, 117, 255],
      // url: "https://www.youtube.com/watch?v=ygfDcR4UdxM",
      url: "#",
      period : "2018.01",
      year : "2018"
    },
    {
      id: 9, //DO NOT CHANGE THIS (Please)😅
      index : 2,
      weightY : 2,
      weightX : 1,
      title: "Picquence camera",
      imageSrc: "/assets/images/labs/thumb/l_009_thumb.jpg",
      color : [229, 211, 211, 255],
      // url: "https://hantaejae.wixsite.com/mysite",
      url : "#",
      period : "2017.08~2017.10",
      year : "2017"
    },
    // {
    //   id: 10, //DO NOT CHANGE THIS (Please)😅
    //   index : 0,
    //   weightY : 2,
    //   weightX : 1,
    //   title: "String Portrait ecommerce",
    //   imageSrc: "/assets/images/labs/thumb/l_010_thumb.jpg",
    //   color : [211, 212, 214, 255],
    //   url: "https://tumblbug.com/55555",
    //   period : "2018.01~2018.10",
    //   year : "2018"
    // },
    {
      id: 11, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Paper Flip-flap",
      imageSrc: "/assets/images/labs/thumb/l_011_thumb.gif",
      color : [172, 146, 137, 255],
      // url: "https://youtu.be/R-3lCupcw98",
      url : "#",
      period : "2018.05~2018.8",
      year : "2018"
    },
    {
      id: 12, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Speed Form",
      imageSrc: "/assets/images/labs/thumb/l_012_thumb.jpg",
      color : [138, 141, 140, 255],
      url: "#",
      period : "2009.09~2009.12",
      year : "2009"
    },
    {
      id: 13, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Self-Portrait Sculpture",
      imageSrc: "/assets/images/labs/thumb/l_013_thumb.jpg",
      color : [142, 146, 143, 255],
      url: "#",
      period : "2009.09~2009.12",
      year : "2009"
    },
    {
      id: 14, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "BB pellets",
      imageSrc: "/assets/images/labs/thumb/l_014_thumb.jpg",
      color : [157, 159, 155, 255],
      url: "#",
      period : "2004.06~2004.10",
      year : "2004"
    },
    {
      id: 15, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Volkswagen",
      imageSrc: "/assets/images/labs/thumb/l_015_thumb.jpg",
      color : [201, 195, 180, 255],
      url: "#",
      period : "2004.06~2004.10",
      year : "2004"
    },
    {
      id: 16, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Hanger",
      imageSrc: "/assets/images/labs/thumb/l_016_thumb.jpg",
      color : [223, 217, 202, 255],
      url: "#",
      period : "2004.06~2004.10",
      year : "2004"
    },
    {
      id: 17, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Noryangjin St.",
      imageSrc: "/assets/images/labs/thumb/l_017_thumb.jpg",
      color : [150, 151, 139, 255],
      url: "#",
      period : "2004.02~2004.04",
      year : "2004"
    },
    {
      id: 18, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Bull Frog",
      imageSrc: "/assets/images/labs/thumb/l_018_thumb.jpg",
      color : [174, 175, 169, 255],
      url: "#",
      period : "2011.09~2011.12",
      year : "2011"
    },
    {
      id: 19, //DO NOT CHANGE THIS (Please)😅
      index : 5,
      title: "City Runner",
      weightY : 2,
      weightX : 2,
      imageSrc: "/assets/images/labs/thumb/l_019_thumb.png",
      color : [125, 126, 128, 255],
      url: "#",
      period : "2012.03~2012.12",
      year : "2012"
    },
    {
      id: 20, //DO NOT CHANGE THIS (Please)😅
      index : 5,
      title: "Sungkok Library",
      weightY : 2,
      weightX : 2,
      imageSrc: "/assets/images/labs/thumb/l_020_thumb.png",
      color : [194, 194, 190, 255],
      url: "#",
      period : "2010.03~2012.06",
      year : "2010"
    },
    {
      id: 21, //DO NOT CHANGE THIS (Please)😅
      index : 5,
      title: "Digital Collage",
      weightY : 2,
      weightX : 1,
      imageSrc: "/assets/images/labs/thumb/l_021_thumb.gif",
      color : [136, 135, 134, 255],
      // url: "https://youtu.be/VKWe9k5qq3Q",
      url : "#",
      period : "2018.06~2018.08",
      year : "2018"
    },
    {
      id: 22, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Eggs",
      imageSrc: "/assets/images/labs/thumb/l_022_thumb.jpg",
      color : [213, 205, 200, 255],
      url: "#",
      period : "2004.06~2004.10",
      year : "2004"
    },
    {
      id: 23, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Drawing texture",
      imageSrc: "/assets/images/labs/thumb/l_023_thumb.jpg",
      color : [59, 62, 71, 255],
      url: "#",
      period : "2004.06~2004.10",
      year : "2004"
    },
    {
      id: 24, //DO NOT CHANGE THIS (Please)😅
      index : 0,
      weightY : 2,
      weightX : 1,
      title: "Generative Art for NFTs",
      imageSrc: "/assets/images/labs/thumb/l_024_thumb.png",
      color : [119, 125, 134, 255],
      url: "#",
      period : "2022.05~",
      year : "2022"
    },
  ],
  
  // Contact Section
  contactPara:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry 's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  social: [
    // Add Or Remove The Link Accordingly
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/taejae-han-8b5b32ba/"
    },
    {
      name: "Github",
      url: "https://github.com/taejaehan"
    },
    {
      name: "Youtube",
      url: "https://www.youtube.com/@worldofcodings"
    }
  ],

  // About Page
  aboutTitle: "TaeJae Han",
  aboutPara:"Meticulous web graphic engineer with over 10 years of front end experience and specialize in 3D web space planning and development. My fulfillment comes from building projects that offer new and creative user experience. That passion and interest have continued from software development to machine learning and blockchain development.",
  navbarYears :[
    {
    label: "2020",
    target: "y-2020"
    },
    {
    label: "2019",
    target: "y-2019"
    },
    {
    label: "2018",
    target: "y-2018"
    },
    {
    label: "2017",
    target: "y-2017"
    },
    {
    label: "2016",
    target: "y-2016"
    },
    {
    label: "2015",
    target: "y-2015"
    },
    {
    label: "2014",
    target: "y-2014"
    },
    {
    label: "2013",
    target: "y-2013"
    },
    {
    label: "2012",
    target: "y-2012"
    },
    {
    label: "2011",
    target: "y-2011"
    },
    {
    label: "2010",
    target: "y-2010"
    },
    {
    label: "2009",
    target: "y-2009"
    },
    {
    label: "2008",
    target: "y-2009"
    },
    {
    label: "2007",
    target: "y-2007"
    },
    {
    label: "2006",
    target: "y-2006"
    },
    {
    label: "2005",
    target: "y-2005"
    },
    {
    label: "2004",
    target: "y-2004"
    },
    {
    label: "2003",
    target: "y-2003"
    }
  ]
};