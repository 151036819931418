import React, { useState, useEffect, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import data from '../data/yourdata';
import Header from './header'
import NavBar, { ElementsWrapper } from './navbar';
import '../App.css';
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import PreloadImage from 'react-preload-image'
import StackGrid from "react-stack-grid";
import FastAverageColor from 'fast-average-color';
import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from '@ramonak/react-progress-bar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: 1000,
    height: "auto",
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  }
}));


const LabListPage = () =>{
// export default function LabListPage() {

    // console.log("LabListPage");
    const history = useHistory();
    const classes = useStyles();

    const labs = data.labs
        .sort( function (a, b) { 
            if(parseInt(a.year) < parseInt(b.year)){ 
                return  1 
            }else{ 
                return  -1 
            } 
        })
        .sort( function (c, d) { 
            if(parseInt(c.year) == parseInt(d.year) && c.index < d.index){ 
                return  -1 
            }else{ 
                return  1 
            } 
        });
        // .sort((a, b) => parseInt(a.year) == parseInt(b.year) && a.index < b.index ? -1 : 1)
    // const labs = data.labs;

    const navbarYears= data.navbarYears;
    // let imageIsReady = false;

    const [imageIsReady, setImageIsReady] = useState(false);
    const [imagePercent, setImagePercent] = useState(0);
    const [imageLoadedNum, setImageLoadedNum] = useState(0);
    function clickItem(e, id, url) {
        
        if(url == '#'){
        history.push('/lab/'+id);
        }else{
        // history.push('/lab/'+id);
        window.open(url, "_blank")
        }

        e.preventDefault();
    }
    const navbarYearsFiltered = navbarYears.filter(navys => {
        //배열의 중에서 조건이 맞으면 true를 리턴
        //메서드 수행 중 true가 리턴되면 메서드 수행을 중단하고 true를 리턴
        return labs.some(lab => {
            if(navys.label === lab.year){
                return true;
            }else{
                return false;
            }
        });
    });


    function imgLoad (data) {
        return new Promise(function(resolve, reject) {
            var img = new Image();
            img.src = data.imageSrc;
            img.onload = function() {

                const fac = new FastAverageColor();
                fac.getColorAsync(img)
                    .then(color => {

                        // console.log("data : ", data)
                        // console.log("color.value : ", color.value)
                        // console.log("color : " + Object.values(color));
                        // console.log("color : " + color.hex);
                        data.color = color.hex;
                        data.color_value = color.value;
                        data.loaded_src = img.src;
                        // console.log('ready:' + img)
                        setImagePercentage(1)
                        resolve(img);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                // console.log('ready:' + img)
                // resolve(img);
            }
            img.onerror = reject
        });
    }
    function readyImage () {
        setImageIsReady(true);
        // document.getElementById("load_con").style.display = "none";
        // document.getElementById("lab_con").style.display = "block";
        // console.log('imageIsReady : ' + imageIsReady)
    }

    function setImagePercentage (plus) {
        var p = 100 / labs.length;
        // setImageLoadedNum(imageLoadedNum+plus);
        setImageLoadedNum(prevNumber => prevNumber + plus);
    }

    useEffect(()=> {
        var p = 100 / labs.length;
        setImagePercent( parseInt(imageLoadedNum * p) );
        if(imageLoadedNum === labs.length){
            readyImage();
        }
    }, [imageLoadedNum]);

    useEffect(()=> {
        let imageLoadpromises = labs.map(imgLoad);
        // Promise.all(imageLoadpromises ).then(function(images){
        //     // console.log('images All READY');
        //     readyImage();
        // }).catch(function(err){ 
        //     console.log('One or more images did not load')
        // });
    }, []);


    const coverWidth = window.innerWidth <= 1000 ? window.innerWidth*0.8 : 1000;
    const navWidthInit = coverWidth/navbarYearsFiltered.length;
    const navWidth = navWidthInit > 86 ? 86 : navWidthInit < 58 ? 58 : navWidthInit;

    return (


        <div className="App">
            {/* <Header name={data.name} contactEmail = {data.contactEmail}></Header> */}
            {/* <NavBar className="nav-bar-test" 
                navbarStyle={{top:100}} 
                navWidth={navWidth}
                coverWidth={coverWidth}
                height={isMobile ? 50 : 60}
                items={navbarYearsFiltered}
                offset={-200}
                duration={500}
                delay={0}
                // containerId="element_body"
            />           */}

            <div className="lab_container" >


                {
                        imageIsReady ? 
                        <></>
                        :
                        <ProgressBar margin="5px 0" bgcolor="#41811c" labelSize="0" height="2px" borderRadius="2px" completed={imagePercent}/>
                }
                <ElementsWrapper items={navbarYearsFiltered} className="test">
                    <></>    
                    <div style={{ width:'100%' }} className="" >
                        <div className={classes.root}>
                            {
                                // imageIsReady ? 
                                <GridList spacing={10} cellHeight={130} className={classes.gridList} cols={isMobile? 1 : 3}>
                                    {labs
                                        // .sort((a, b) => parseInt(a.year) < parseInt(b.year) ? 1 : -1)
                                        // .sort((a, b) => parseInt(a.year) == parseInt(b.year) && a.index < b.index ? -1 : 1)
                                        .map((tile) => (
                                        // <GridListTile style={{backgroundColor:"rgba("+tile.color[0]+","+tile.color[1]+","+tile.color[2]+",0.5)"}} className="gird_tile" onClick={(e) => clickItem(e, tile.id, tile.url)}  key={tile.id} name={"y-"+tile.year} rows={ isMobile? 2 : tile.weightY } cols={ isMobile? 1 : tile.weightX }>
                                        <GridListTile style={{backgroundColor:"rgba("+tile.color[0]+","+tile.color[1]+","+tile.color[2]+",0.5)"}} className="gird_tile" onClick={(e) => clickItem(e, tile.id, tile.url)}  key={tile.id} name={"y-"+tile.year} rows={ isMobile? 2 : 2 } cols={ isMobile? 1 : 1 }>
                                            <PreloadImage
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                    // borderRadius:"15px",
                                                    backgroundColor: tile.color
                                                }}
                                                // innerStyle={{
                                                //     borderRadius:"15px",
                                                // }}
                                                src={tile.imageSrc} 
                                                // src={tile.loaded_src} 
                                                alt={tile.title}
                                            />
                                            <GridListTileBar
                                            // style={{backgroundColor:tile.color}}
                                            // style={{backgroundColor:"rgba("+tile.color_value[0]+","+tile.color_value[1]+","+tile.color_value[2]+",0.5)"}}
                                            style={{backgroundColor:"rgba("+tile.color[0]+","+tile.color[1]+","+tile.color[2]+",0.5)"}}
                                            title={tile.title}
                                            actionIcon={
                                                <IconButton aria-label={`info about ${tile.title}`} className={classes.icon}>
                                                </IconButton>
                                            }
                                            />
                                        </GridListTile>
                                    ))}
                                </GridList>
                                // :
                                // <></>
                            }
                        </div>
                    </div>
                </ElementsWrapper>
            </div>
        </div>
    )
  
}

export default LabListPage;